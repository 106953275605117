import { EnumValues } from "firecms";

export const locales: EnumValues = {
  es: "Spanish",
  de: "German",
  en: "English",
  it: "Italian",
  fr: {
    id: "fr",
    label: "French",
    disabled: true
  }
};

export const currencies: EnumValues = [
  { id: "EUR", label: "Euros", color: "blueDark" },
  { id: "DOL", label: "Dollars", color: "greenLight" }
];

export const categories: EnumValues = {
  art_and_decoration: "Art and decoration",
  art_design_books: "Art and design books",
  babys: "Babies and kids",
  backpacks: "Backpacks and bags",
  bath: "Bath",
  bicycle: "Bicycle",
  books: "Books",
  cameras: "Cameras",
  clothing_man: "Clothing man",
  clothing_woman: "Clothing woman",
  coffee_and_tea: "Coffee and tea",
  cookbooks: "Cookbooks",
  delicatessen: "Delicatessen",
  desk_accessories: "Desk accessories",
  exercise_equipment: "Exercise equipment",
  furniture: "Furniture",
  gardening: "Gardening",
  headphones: "Headphones",
  home_accessories: "Home accessories",
  home_storage: "Home storage",
  kitchen: "Kitchen",
  lighting: "Lighting",
  music: "Music",
  outdoors: "Outdoors",
  personal_care: "Personal care",
  photography_books: "Photography books",
  serveware: "Serveware",
  smart_home: "Smart Home",
  sneakers: "Sneakers",
  speakers: "Speakers",
  sunglasses: "Sunglasses",
  toys_and_games: "Toys and games",
  watches: "Watches"
};



export const modalidades: EnumValues = {
  presencial: "Presencial",
  online: "Online",
  semipresencial: "Semipresencial",
  executive: "Executive",
  hibrida: "Hibrida",
  at_home: "At Home",
}

export const turnos: EnumValues = {
  tarde: "tarde",
  mañana: "mañana",
  fin_de_semana: "fin_de_semana",
  viernes_sabado: "viernes_sabado",
  na: "na"
}

export const channels: EnumValues = {
  sem: "sem",
  seo: "seo",
  all: "all",
}

type StringAnyValue = {
  [key: string]: string;
};

export const sedes: StringAnyValue = {
  'Álava': 'Álava',
  'Albacete': 'Albacete',
  'Alicante': 'Alicante',
  'Almería': 'Almería',
  'Asturias': 'Asturias',
  'Ávila': 'Ávila',
  'Badajoz': 'Badajoz',
  'Baleares': 'Baleares',
  'Barcelona': 'Barcelona',
  'Bilbao': 'Bilbao',
  'Burgos': 'Burgos',
  'Cáceres': 'Cáceres',
  'Cádiz': 'Cádiz',
  'Cantabria': 'Cantabria',
  'Castellón': 'Castellón',
  'Ciudad Real': 'Ciudad Real',
  'Córdoba': 'Córdoba',
  'Coruña': 'Coruña',
  'Cuenca': 'Cuenca',
  'Girona': 'Girona',
  'Granada': 'Granada',
  'Guadalajara': 'Guadalajara',
  'Guipúzcoa': 'Guipúzcoa',
  'Huelva': 'Huelva',
  'Huesca': 'Huesca',
  'Jaén': 'Jaén',
  'León': 'León',
  'Lleida': 'Lleida',
  'Logroño': 'Logroño',
  'Lugo': 'Lugo',
  'Madrid': 'Madrid',
  'Málaga': 'Málaga',
  'Murcia': 'Murcia',
  'Navarra': 'Navarra',
  'Orense': 'Orense',
  'Palencia': 'Palencia',
  'Pontevedra': 'Pontevedra',
  'Salamanca': 'Salamanca',
  'Santander': 'Santander',
  'Segovia': 'Segovia',
  'Sevilla': 'Sevilla',
  'Soria': 'Soria',
  'Tarragona': 'Tarragona',
  'Teruel': 'Teruel',
  'Toledo': 'Toledo',
  'Valencia': 'Valencia',
  'Valladolid': 'Valladolid',
  'Vizcaya': 'Vizcaya',
  'Zamora': 'Zamora',
  'Zaragoza': 'Zaragoza',
};
export const centros: StringAnyValue = {};
export const verticals: StringAnyValue = {};

export const statuses: EnumValues = {
  "new_lead": "Nuevo Lead",
  "contacted": "Contactado",
  "qualified": "Calificado",
  "negotiation": "Negociación",
  "closed_won": "Cerrado Exitoso",
  "closed_lost": "Cerrado Perdido",
  "follow_up": "Seguimiento",
  "on_hold": "En Espera",
  "unqualified": "No Calificado",
  "inactive": "Inactivo",
  "success": "Éxito",
  "error": "Error"
}