import { EntityCallbacks, EntityOnSaveProps, buildCollection, buildProperty } from "firecms";

import { Lead } from "../types";
import { centros, modalidades, statuses } from "./enums";

import { collection, doc, setDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";

// Assuming you have already initialized Firebase and imported the firestore module

const updateDocument = async (collectionName: string, documentId: any, newValues: any) => {
  try {
    const db = getFirestore(); // Get the Firestore instance
    const docRef = doc(collection(db, collectionName), documentId);
    await setDoc(docRef, newValues, { merge: true });
    console.log(`Document updated successfully in ${collectionName}`);
  } catch (error) {
    console.error(`Error updating document in ${collectionName}:`, error);
  }
};


const sedes: any = {}

export const leadCallbacks: EntityCallbacks<Lead> = {
  onFetch: ({ entity }) => {
    entity.values.status = (entity.values.status || 'new_lead').toLowerCase()
    entity.values.mode = (entity.values.mode || '').toLowerCase()
    const sede = (entity.values.sede || '')
    const centro = (entity.values.centro || '')
    sedes[sede] = sede;
    centros[centro] = centro;
    // status by default "new_lead"
    entity.values.status = entity.values.status || 'new_lead'
    return entity
  },
  onSaveSuccess: (props: EntityOnSaveProps<Lead>) => {
    console.log("onSaveSuccess", props);
    const { values, path, entityId } = props;
    if (path !== 'zoho') {
      const collectionName = 'zoho'
      // clone the values to another collection of firestore
      const newValues = { ...values }
      // console.log({values, path, props})
      // updateDocument
      console.log({updateDocument: {collectionName, entityId, newValues}})
      return updateDocument(collectionName, entityId, newValues)
    }
  },
};

export const odooCollection = buildCollection<Lead>({
  path: "odoo",
  name: "ISEP",
  singularName: "ISEP Lead",
  icon: "Person",
  group: "Main",
  description: "List of leads",
  textSearchEnabled: true,
  callbacks: leadCallbacks,
  initialSort: ["created_at", "desc"],
  permissions: ({ authController }) => ({
    edit: true,
    create: false,
    delete: Boolean(authController.extra?.roles?.admin)
  }),
  properties: {
    id: {
      dataType: "string",
      name: "Ciclo",
      readOnly: true,
      validation: {
        required: true
      }
    },
    created_at: {
      dataType: "date",
      name: "Created",
      readOnly: true,
      mode: "date"
    },
    email: {
      dataType: "string",
      name: "Email",
      description: "Email address of the lead",
      readOnly: true,
      validation: {
        required: true
      }
    },
    name: {
      dataType: "string",
      name: "Name",
      description: "Name of the lead",
      readOnly: true,
      validation: {
        required: true
      }
    },
    phone: {
      dataType: "string",
      name: "Phone",
      description: "Phone number",
      readOnly: true,
      validation: {
        required: true
      }
    },
    mode: {
      dataType: "string",
      name: "Mode",
      description: "Mode",
      readOnly: true,
      validation: {
        required: true
      },
      enumValues: modalidades
    },
    // sede: {
    //   dataType: "string",
    //   name: "Centro",
    //   description: "Centro",
    //   readOnly: true,
    //   validation: {
    //     required: true
    //   },
    //   enumValues: sedes
    // },
    // x_sede_id: {
    //   dataType: "string",
    //   name: "Sede",
    //   description: "Sede",
    //   readOnly: true,
    //   validation: {
    //     required: false
    //   },
    //   disabled: true,
    //   enumValues: centros
    // },
    status: {
      dataType: "string",
      name: "Status",
      description: "Status",
      validation: {
        required: false
      },
      enumValues: statuses
    },
    comments: {
      dataType: "string",
      name: "Comments",
      description: "Additional comments",
      validation: {
        required: false
      }
    },
    source: buildProperty({
      name: "UTM",
      dataType: "map",
      properties: {
        utm_source: {
          name: "UTM Source",
          dataType: "string",
          readOnly: true,
          validation: {
            required: false
          }
        },
        utm_medium: {
          name: "UTM Medium",
          dataType: "string",
          readOnly: true,
          validation: {
            required: false
          }
        },
        utm_campaign: {
          name: "UTM Campaign",
          dataType: "string",
          readOnly: true,
          validation: {
            required: false
          }
        },
        utm_term: {
          name: "UTM Term",
          dataType: "string",
          readOnly: true,
          validation: {
            required: false
          }
        },
        utm_content: {
          name: "UTM Content",
          dataType: "string",
          readOnly: true,
          validation: {
            required: false
          }
        },
        "campaign_id": {
          name: "campaign_id",
          dataType: "string",
          readOnly: true,
          validation: {required: false}
        },
        "utm_campaign_id": {
          name: "utm_campaign_id",
          dataType: "string",
          readOnly: true,
          validation: {required: false}
        },
        "utm_device": {
          name: "utm_device",
          dataType: "string",
          readOnly: true,
          validation: {required: false}
        },
        "utm_estrategia": {
          name: "utm_estrategia",
          dataType: "string",
          readOnly: true,
          validation: {required: false}
        },
        "utm_network": {
          name: "utm_network",
          dataType: "string",
          readOnly: true,
          validation: {required: false}
        },
        "gclid": {
          name: "gclid",
          dataType: "string",
          readOnly: true,
          validation: {required: false}
        },
        "utm_centro": {
          name: "utm_centro",
          dataType: "string",
          readOnly: true,
          validation: {required: false}
        },
        "utm_campaign_name": {
          name: "utm_campaign_name",
          dataType: "string",
          readOnly: true,
          validation: {required: false}
        },
        "google_id": {
          name: "google_id",
          dataType: "string",
          readOnly: true,
          validation: {required: false}
        },
        "facebook_id": {
          name: "facebook_id",
          dataType: "string",
          readOnly: true,
          validation: {required: false}
        },
        "utm_type": {
          name: "utm_type",
          dataType: "string",
          readOnly: true,
          validation: {required: false}
        },
        "utm_adset_id": {
          name: "utm_adset_id",
          dataType: "string",
          readOnly: true,
          validation: {required: false}
        },
        "utm_channel": {
          name: "utm_channel",
          dataType: "string",
          readOnly: true,
          validation: {required: false}
        },
        "utm_ad_id": {
          name: "utm_ad_id",
          dataType: "string",
          readOnly: true,
          validation: {required: false}
        },
        "twitter_id": {
          name: "twitter_id",
          dataType: "string",
          readOnly: true,
          validation: {required: false}
        },
        "utm_gclid": {
          name: "utm_gclid",
          dataType: "string",
          readOnly: true,
          validation: {required: false}
        },
        "gad_source": {
          name: "gad_source",
          dataType: "string",
          readOnly: true,
          validation: {required: false}
        },
        "utm_placement": {
          name: "utm_placement",
          dataType: "string",
          readOnly: true,
          validation: {required: false}
        },
        utm_fbclid: {
          name: 'utm_fbclid',
          dataType: 'string',
          readOnly: true,
          validation: { required: false }
        },
        utm_site_source_name: {
          name: 'utm_site_source_name',
          dataType: 'string',
          readOnly: true,
          validation: { required: false }
        },
        tipo_conversion: {
          name: 'tipo_conversion',
          dataType: 'string',
          readOnly: true,
          validation: { required: false }
        },
        utm_perfil: {
          name: 'utm_perfil',
          dataType: 'string',
          readOnly: true,
          validation: { required: false }
        },
      },
      expanded: true
    }),
    // product: buildProperty({
    //   name: "Product",
    //   dataType: "map",
    //   properties: {
    //     id: {
    //       name: "ID",
    //       dataType: "string",
    //       readOnly: true
    //     },
    //     nombre_curso: {
    //       name: "Name",
    //       dataType: "string",
    //       readOnly: true
    //     },
    //     matricula: {
    //       name: "Matricula",
    //       dataType: "string",
    //       readOnly: true
    //     },
    //     descuento_matricula: {
    //       name: "Descuento Matricula",
    //       dataType: "string",
    //       readOnly: true
    //     },
    //     mensualidad: {
    //       name: "Mensualidad",
    //       dataType: "string",
    //       readOnly: true
    //     },
    //     num_mensualidades: {
    //       name: "Num Mensualidades",
    //       dataType: "string",
    //       readOnly: true
    //     },
    //     curso_anio: {
    //       name: "Curso Anio",
    //       dataType: "string",
    //       readOnly: true
    //     },
    //     beca: {
    //       name: "Beca",
    //       dataType: "string",
    //       readOnly: true
    //     },
    //     descuento: {
    //       name: "Descuento",
    //       dataType: "string",
    //       readOnly: true
    //     },
    //     amount: {
    //       name: "Amount",
    //       dataType: "string",
    //       readOnly: true
    //     }
    //     // Add more properties of the product if needed
    //   },
    //   config: {
    //     collectionPath: 'products',
    //     selectVariant: "popup",
    //     previewProperties: ["nombre_curso", "matricula"], // Display the product name in the reference preview
    //     filter: ["nombre_curso"] // Allow filtering products by name
    //   }
    // }),
    from: {
      dataType: "string",
      name: "From",
      description: "URL source",
      readOnly: true,
      validation: {
        required: false
      }
    }
  }
});