export const firebaseConfig = {
  // apiKey: "AIzaSyAHrXab8-alae-VVsTwdGiPJTP6HTx5Lv4",
  // authDomain: "metro-dora.firebaseapp.com",
  // projectId: "metro-dora",
  // storageBucket: "metro-dora.appspot.com",
  // messagingSenderId: "879004453306",
  // appId: "1:879004453306:web:f112cf2db4fed117073583"

  // /*Juno*/
  apiKey: "AIzaSyAAZxDU2JQOA13wAfGPQSFxm4So8jF29jM",
  authDomain: "cms-juno.firebaseapp.com",
  databaseURL: "https://cms-juno-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "cms-juno",
  storageBucket: "cms-juno.appspot.com",
  messagingSenderId: "432951421835",
  appId: "1:432951421835:web:40cdb5e8d2dad816f85d8e"
};
